import React from 'react';

const Header = () => {
    return (
        <header>
            <h1>Radio Ain Karim <span>104.4 FM</span></h1>
        </header>
     );
}

export default Header;