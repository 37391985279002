import React, { Component } from 'react';


class Player extends Component {
    constructor(props) {
        super(props);
        this.state = {
            radioStreamActive: false,
        }
    }

    componentDidMount() {
        this.radio = new Audio();
        this.radio.src = 'https://stream.profeto.pl/radioainkarim.mp3';
    }

    handleClickRadioStream = () => {
        if (!this.state.radioStreamActive) {
            this.radio.play();
        } else {
            this.radio.pause()
        };

        this.setState(prevState => ({
            radioStreamActive: !prevState.radioStreamActive,
        }))
    }

    render() {
        const { radioStreamActive } = this.state;
        return (
            <main>
                <button
                    title="Kliknij by posłuchać radia"
                    onClick={this.handleClickRadioStream}
                >

                    {!radioStreamActive ?
                        <i className="fas fa-play"></i> :
                        <i className="fas fa-pause"></i>
                    }

                </button>
            </main>
        );
    }
}

export default Player;